main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  line-height: 1.6;

  @include themify($themes) {
    color: themed('colorText');
  }
}

* {
  box-sizing: inherit;
}

ul,
ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $color-blue;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-blue-hover;
  }
}

img {
  width: 100%;
}

input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  font-size: 16px;
  color: #808090 !important;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-size: 16px;
  color: #808090 !important;
  opacity: 1;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 16px;
  color: #808090 !important;
  opacity: 1;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 16px;
  color: #808090 !important;
}
input::-ms-input-placeholder {
  /* Microsoft Edge */
  font-size: 16px;
  color: #808090 !important;
}

input::placeholder {
  /* Most modern browsers support this now. */
  font-size: 16px;
  color: #808090 !important;
}

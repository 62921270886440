.recharts-text {
  @include themify($themes) {
    fill: themed('colorText');
  }
  opacity: 1;
}

.recharts-tooltip-wrapper {
  .recharts-default-tooltip {
    @include themify($themes) {
      background: themed('colorBackgroundBody') !important;
    }
    border: 0 !important;
  }
}

.dashboard__weekly-stat {
  hr {
    margin-bottom: 20px;
  }

  &-chart {
    display: flex;
    margin: 0 0 31px 0;
    flex-wrap: wrap;
    justify-content: space-around;

    .recharts-surface {
      width: 100%;
      height: 100%;
    }

    &-item {
      width: 110px;
      margin-bottom: 15px;

      @media screen and (max-width: 370px) {
        @include directify($directions) {
          #{directed('margin-left')}: 10px;
        }
      }

      @media screen and (min-width: 1200px) {
        @include directify($directions) {
          #{directed('margin-left')}: 10px;
        }
      }

      @media screen and (min-width: 1400px) {
        @include directify($directions) {
          #{directed('margin-left')}: 0px;
        }
      }
    }

    &-pie {
      display: block;
      position: relative;
      text-align: center;
      height: 110px;

      .recharts-responsive-container {
        width: 100% !important;
      }
    }

    &-pie-wrapper {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &-label {
    position: absolute;
    top: calc(50% - 10px);
    width: 100%;
    margin: 0;
    animation: label 1.5s ease-in;
    font-size: 24px;
    line-height: 24px;
  }

  &-info {
    margin-top: 10px;
    font-size: 13px;
    line-height: 1.38;
    text-align: center;
    p {
      color: $color-additional;
    }
  }
}

.drag-list {
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;
  width: 100%;
  overflow: auto;
  padding-inline-start: 0px;
  margin-bottom: 30px;
}

.drag-list-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 10px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  user-select: none;
  color: $color-black;
  font-weight: 400;
  cursor: grab;
  &__text {
    margin-right: 30px;
    display: flex;
    font-size: 16px;
    & .order {
      font-size: 16px;
      margin: 0 10px;
    }
  }
}

.drag-list-item:hover {
  background-color: rgba(15, 31, 43, 0.1);
}

.handle {
  margin-right: 10px;
  cursor: grab;
  position: absolute;
  right: 0;
}

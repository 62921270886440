.wizard {
  padding-top: 90px;
  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  @media screen and (max-width: 480px) {
    &.card-body {
      padding: 90px 18px 0 18px;
    }
  }

  .wizard__form {
    max-width: 880px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 100px;
    padding: 0 25px;

    @media screen and (max-width: 480px) {
      padding: 0;
      margin-bottom: 0;
    }

    .wizard__toolbar {
      margin-left: auto;
      margin-right: auto;
      margin-top: 40px;
      @media screen and (max-width: 480px) {
        margin: unset;
        flex-direction: row;
        flex-wrap: unset;
        width: 100%;
        margin-top: 30px;

        & button {
          width: 100%;
        }
      }

      & button {
        font-size: 18px;
        font-weight: 600;
        width: 150px;
      }

      & button.previous {
        margin-right: 36px;
      }
    }
  }

  .wizard__form__large {
    max-width: 90%;
  }

  .wizard__form__custom__label {
    display: flex;
    width: 100%;
    margin-bottom: 2%;
    font-size: 16px;

    & span {
      font-size: 16px;
    }
  }
}

.wizard__steps {
  display: flex;
}

.wizard__step {
  width: 100%;
  text-align: center;
  height: 55px;
  text-transform: uppercase;
  display: flex;
  transition: background 0.3s;
  border-radius: 5px;

  @media screen and (max-width: 480px) {
    display: none;
  }

  @include themify($themes) {
    border: 1px solid themed('colorBorder');
    background: themed('colorHover');
  }

  p {
    font-weight: 700;
    margin: auto;
    font-size: 14px;
    transition: all 0.3s;
  }

  &.wizard__step--active {
    @media screen and (max-width: 480px) {
      display: flex;
    }

    background: $color-yellow;
    border-color: $color-yellow;

    p {
      color: $color-black;
    }
  }
}

.wizard__form-wrapper {
  display: flex;
  justify-content: center;
}

.wizard__title {
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
}

.wizard__description {
  color: $color-additional;
  margin: 0;
  max-width: 410px;
}

.wizard .card-body {
  padding: 0;
}

.squared-corner-theme {
  .wizard__step {
    border-radius: 0;
  }
}

.wizard__form__custom {
  display: flex;
  flex-direction: column;
}

.wizard__form__material__select__custom {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

.wizard__form__custom__label {
  font-size: 16px;

  & span {
    font-size: 16px;
  }
}

.container {
  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }
}

.container__wrap {
  padding-top: 90px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  background: #f6f7f9;
  @include directify($directions) {
    #{directed('padding-left')}: 0px;
  }

  // @include themify($themes) {
  //   background: themed('colorBackgroundBody');
  // }

  @media screen and (min-width: 576px) {
    @include directify($directions) {
      #{directed('padding-left')}: 290px;
    }
  }

  @media screen and (max-width: 576px) {
    padding-top: 90px;
  }
}

.layout {
  &.layout--collapse {
    & + .container__wrap {
      @include directify($directions) {
        #{directed('padding-left')}: 0px;
      }

      @media screen and (min-width: 576px) {
        @include directify($directions) {
          #{directed('padding-left')}: 60px;
        }
      }
    }
  }

  &.layout--top-navigation {
    & + .container__wrap {
      @media screen and (min-width: 576px) {
        @include directify($directions) {
          #{directed('padding-left')}: 0px;
        }
      }
    }
  }
}

// .layout {

//   &.layout--collapse {

//     & + .container__wrap {
//       padding-left: 0;

//       @media screen and (min-width: 576px) {
//         padding-left: 60px;
//       }
//     }
//   }

//   &.layout--top-navigation{

//     & + .container__wrap{

//       @media screen and (min-width: 576px) {
//         padding-left: 0;
//       }
//     }
//   }
// }

// .container__wrap__custom {
//   padding-left: 0;
//   padding-top: 90px;
//   min-height: 100vh;
//   transition: padding-left 0.3s;
//   background: white;

//   // @include themify($themes) {
//   //   background: themed('colorBackgroundBody');
//   // }

//   @media screen and (min-width: 576px) {
//     padding-left: 250px;
//   }
// }

// .layout {

//   &.layout--collapse {

//     & + .container__wrap__custom {
//       padding-left: 0;

//       @media screen and (min-width: 576px) {
//         padding-left: 60px;
//       }
//     }
//   }

//   &.layout--top-navigation{

//     & + .container__wrap__custom {

//       @media screen and (min-width: 576px) {
//         padding-left: 0;
//       }
//     }
//   }
// }

// .container__wrap__custom__profile {
//   padding-left: 0;
//   padding-top: 60px;
//   min-height: 100vh;
//   transition: padding-left 0.3s;
//   // background: #0F1F2B;
//   display: flex;

//   // @include themify($themes) {
//   //   background: themed('colorBackgroundBody');
//   // }

//   @media screen and (min-width: 576px) {
//     padding-left: 250px;
//   }
// }

// .layout {

//   &.layout--collapse {

//     & + .container__wrap__custom__profile {
//       padding-left: 0;

//       @media screen and (min-width: 576px) {
//         padding-left: 60px;
//       }
//     }
//   }

//   &.layout--top-navigation{

//     & + .container__wrap__custom__profile {

//       @media screen and (min-width: 576px) {
//         padding-left: 0;
//       }
//     }
//   }
// }

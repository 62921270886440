.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.wizard .wizard__form__custom__label > .wizard__from__custom__font {
  font-size: 15px;
}

.wizard__card__body__custom {
  @media screen and (max-width: 480px) {
    padding: 0;

    & p:first-child {
    }
  }
}

@media screen and (max-width: 480px) {
  .radio-inline-align-custom {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 720px) {
  .radio-inline-align-custom {
    // display: flex;
  }
}

.wizard__form__radio__title {
  margin: 5% 0 2% 0;
  font-size: 16px;
}

.uploaded__margin {
  margin-top: 4%;
  overflow-wrap: anywhere;
}

.profile__card__custom {
  background-color: $color-background-gray !important;
  width: 30%;
  border-radius: 0;
}

.company_logo {
  margin-top: 2%;
  width: 200px;
}

.subhead_custom {
  font-size: 16px !important;
}

.wizard__steps__tab {
  font-size: 16px !important;
}

.wizard__form__title__custom {
  font-size: 28px !important;
  font-weight: 600;
}

.wizard__form__label__custom {
  font-size: 16px !important;
}

.dropzone__custom {
  font-size: 16px;
}

.select__custom {
  font-size: 16px;
}

.wizard__form__button__custom {
  font-weight: 600;
}

@media screen and (max-width: 480px) {
  .subhead_custom {
    font-size: 16px !important;
  }

  .dashboard-card-content {
    font-size: 16px;
  }

  .score__card__custom {
    margin-bottom: 4%;
    min-width: 100%;
  }

  .profile__card__custom {
    width: 100%;
  }

  .company_logo {
    margin-top: 10%;
  }
}

.material-form__label__custom {
  font-size: 16px;
}

.wizard__bold__title {
  font-weight: 600;
}

.MuiTabs-indicator {
  background-color: $color-yellow !important;
}

.secondary__label {
  display: block;
  font-size: 14px;
  font-style: italic;
  color: $color-secondary-text-gray;
}

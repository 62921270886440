.material-form {
  // @include directify($directions) {
  //   text-align: directed('left');
  // }

  // .material-form__field {
  //   width: 100%;
  //   margin-bottom: 20px;

  //   div {
  //     font-size: 18px;
  //     @include themify($themes) {
  //       color: themed('colorText');
  //     }
  //   }

  //   & > div:before {

  //     @include themify($themes) {
  //       border-bottom-color: themed('colorFieldsBorder');
  //     }
  //   }

  //   & > div:hover:before {
  //     border-bottom: 1px solid $color-yellow !important;
  //   }

  //   & > div:after {
  //     border-color: $color-yellow;
  //   }

  //   input, textarea {
  //     font-size: 18px;

  //     @include themify($themes) {
  //       color: themed('colorText');
  //     }
  //   }
  //   label {
  //     @include directify($directions) {
  //       #{directed('left')}: 0px;
  //       #{directed('right')}: auto;
  //     }
  //   }

  //   svg {
  //     @include directify($directions) {
  //       #{directed('left')}: auto;
  //       #{directed('right')}: 0;
  //     }

  //     @include themify($themes) {
  //       fill: themed('colorText');
  //     }
  //   }
  // }

  // .material-form__label {
  //   margin: 0;
  //   font-size: 16px;

  //   @include themify($themes) {
  //     color: themed('colorText');
  //   }
  // }

  // label {
  //   font-size: 16px;

  //   @include themify($themes) {
  //     color: themed('colorText');
  //   }

  //   &[data-shrink="true"] {
  //     transform: translate(0, 1.5px) scale(0.85);
  //   }
  // }
}

.material-form__option {
  font-size: 16px !important;
  height: 32px !important;
}

.material-multiselect-chips__custom {
  display: flex;
  flex-wrap: wrap;
}

.material-multiselect-chip__custom {
  margin: 2px;
  background-color: $color-yellow !important;
  color: $color-black !important;
}

.material-multiselect-option__custom {
  font-size: 16px;
}

.material-multiselect-checkbox__custom.Mui-checked {
  color: $color-yellow !important;
}

.material-multiselect-checkbox-label__custom > span {
  font-size: 16px !important;
}

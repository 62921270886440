@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.welcome__container {
  display: flex;
  justify-content: center;
  min-height: 85vh; // was 100
  align-items: center;
  background: white;

  .welcome-page-container {
    // min-width: 75%;

    -webkit-animation: fadein 0.75s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.75s; /* Firefox < 16 */
    -ms-animation: fadein 0.75s; /* Internet Explorer */
    -o-animation: fadein 0.75s; /* Opera < 12.1 */
    animation: fadein 0.75s;

    &__card {
      position: relative;
      height: 100%;
      padding: 30px;
      border-radius: 3px;
      background-color: #fff;

      &__button-bar {
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }

      &__button {
        font-weight: 600;
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .welcome-page-container {
    .welcome-page-container {
      &__card {
        padding: 0;
      }
    }
  }
}

.mb-40 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
}

.text-large {
  font-size: 16px;
  margin: 0 20%;
}

@media screen and (max-width: 480px) {
  .text-large {
    margin: 0;
  }
}

.text-center {
  text-align: center;
}

.questions-page {
  &__question {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__title {
      font-size: 20px;
      line-height: 32px;
    }

    @media screen and (max-width: 480px) {
      &__title {
        text-align: center;
      }
    }

    &__input {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 25px 0 25px 0;
      height: 30px;

      &__field {
        width: 100%;
      }
    }
  }

  &__button {
    width: 150px;
    font-weight: 600;
  }

  @media screen and (max-width: 480px) {
    &__button {
      width: 100px;
    }
    &__button-bar {
      display: flex;
      justify-content: center;
    }

    &__title {
      text-align: center;
    }
  }

  &__back-button {
    width: auto !important;
    background: none !important;
    color: black !important;
    border: none !important;
    display: flex;
    border: none !important;
    padding: 10px 0;
    padding-right: 25px;
    cursor: pointer;
    outline: inherit !important;
  }
}

.long-arrow-left {
  display: block;
  margin-left: 4px;
  margin-right: 20px;
  margin-top: 6px;
  width: 10px;
  height: 10px;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
}

.arrow-left,
.long-arrow-left {
  transform: rotate(-45deg);
}

.long-arrow-left::after {
  content: '';
  display: block;
  width: 1.5px;
  height: 16px;
  background-color: black;
  transform: rotate(-45deg) translate(5px, 1px);
  left: 0;
  top: 0;
}

.confirm-page-container-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.confirm-page-text {
  margin-bottom: 35px;
}

.onboarding__survey__container
  .welcome-page-container__card__button-bar.confirm-button-bar {
  margin-top: 60px;

  & .welcome-page-container__card__button {
    font-weight: 600;
  }
}

// .onboarding__survey__container {
//   display: flex;
//   justify-content: center;
//   height: 85vh;
// }

$checkmark-width-left: 15px;
$checkmark-width-right: 30px;
$checkmark-position-top: 32px;
$checkmark-position-left: 21px;
$animation-speed: 0.3s;
// Wrapper

.check-wrap {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid #0ebab6;
  position: relative;
  overflow: hidden;
  animation: wrap $animation-speed ease-in-out forwards;
  animation-delay: 0.3s;
  transform: scale(0);
  margin-bottom: 22px;

  // Checkmark
  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: white;
    width: 0;
    height: 5px;
    transform-origin: left;
    animation-duration: $animation-speed;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  &::before {
    top: $checkmark-position-top;
    left: $checkmark-position-left;
    transform: rotate(45deg);
    animation-name: left;
    animation-delay: 0.8s;
  }

  &::after {
    top: $checkmark-position-top + 10px;
    left: $checkmark-position-left + 8px;
    transform: rotate(-45deg);
    animation-name: right;
    animation-delay: 1.1s;
  }
}

// Animation wrapper
@keyframes wrap {
  0% {
    background-color: transparent;
    transform: scale(0);
  }
  100% {
    background-color: #0ebab6;
    transform: scale(1);
  }
}

// Checkmark keyframe mixin
@mixin checkmark($name, $width) {
  @keyframes #{$name} {
    0% {
      width: 0;
    }
    100% {
      width: $width;
    }
  }
}

// Checkmark keyframes
@include checkmark(left, $checkmark-width-left);
@include checkmark(right, $checkmark-width-right);

.thank_you_page {
  height: 75vh;
}

.pre_screen__container {
  padding: 140px 200px 0 200px;
  width: 100%;
  max-width: 100%;

  @media screen and (max-width: 480px) {
    padding: 0;
    padding-top: 60px;
  }
}

.form__pre-screen-questions {
  display: flex;
  // justify-content: center;
  // align-items: center;
  background: white;
  margin-left: 2rem;
  margin-right: 2rem;
  & .wizard__toolbar {
    margin: 20px 0 0 0 !important;
  }

  & .form__form-group-field {
    margin: 0 !important;
    padding: 0 !important;
  }

  & .wizard__form__title-new-line {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  @media screen and (min-width: 720px) {
    & .radio-inline-align-custom {
      display: block;
    }
  }
}

.notification {
  max-width: 400px;
  width: calc(100% - 50px);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
  padding: 20px 40px 30px 25px;
  background: $color-white;
  position: relative;
  margin: 10px 25px;

  &.notification--image {
    height: 106px;
    overflow: hidden;
  }

  &.notification--full-wide {
    max-width: 100vw;
    width: 100vw;
    margin: 0;
    padding: 20px 40px 20px 25px;

    .notification__message {
      text-align: center;
      width: calc(100% - 30px);
    }
  }

  &.notification--theme-light {
    background: $color-white;
  }

  &.notification--theme-dark {
    background: $color-black-background;
    $color-black-background: 1px solid #33333a;
    color: $color-dusty-white;
  }

  &.notification--primary {
    background: $color-blue;

    .notification__message,
    .notification__title {
      color: $color-white;
    }
  }

  &.notification--success {
    background: #f0c530;

    .notification__message,
    .notification__title {
      color: $color-black;
    }
  }

  &.notification--warning {
    background: $color-yellow;

    .notification__message,
    .notification__title {
      color: $color-white;
    }
  }

  &.notification--danger {
    background: $color-red-toast;

    .notification__message,
    .notification__title {
      color: $color-white;
    }
  }
}

.rc-notification-notice-close-x {
  color: $color-additional;
}

.notification__message {
  margin-top: 0;
  font-size: 16px;
  font-weight: 600;
}

.notification__title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.notification__image {
  position: absolute;
  height: 106px;
  width: 106px;
  top: 0;

  @include directify($directions) {
    #{directed('left')}: 0;
  }

  & ~ * {
    @include directify($directions) {
      #{directed('padding-left')}: 106px;
    }
  }
}

.sidebar.sidebar--collapse + .container__wrap {
  .notifications-tl,
  .notifications-bl {
    padding-left: 0 !important;
  }
}

.toast-custom {
  background: #fff;
  padding: 20px 15px 20px 20px;
  border-radius: 10px;
  border-left: 5px solid #0ebab6;
  box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.15);
  max-width: 430px;
  width: calc(100% - 50px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 10px 25px;
  min-height: 122px;
  min-width: 430px;

  &__content {
    display: flex;
    align-items: center;

    &__details {
      margin-left: 15px;
      max-width: 320px;

      & span {
        font-size: 20px;
        font-weight: 500;
      }

      & p {
        color: #878787;
        margin-right: 20px;
      }
    }
  }
}

$checkmark-width-left: 15px;
$checkmark-width-right: 30px;
$checkmark-position-top: 25px;
$checkmark-position-left: 13px;
$animation-speed: 0.3s;
// Wrapper

.toast-check-mark {
  width: 54px;
  height: 54px;
  border-radius: 70%;
  border: 2px solid #0ebab6;
  position: relative;
  overflow: hidden;
  animation: toastCheckMark $animation-speed ease-in-out forwards;
  animation-delay: 0.3s;
  transform: scale(0);

  // Checkmark
  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: white;
    width: 0;
    height: 5px;
    transform-origin: left;
    animation-duration: $animation-speed;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  &::before {
    top: $checkmark-position-top;
    left: $checkmark-position-left;
    transform: rotate(45deg);
    animation-name: left;
    animation-delay: 0.8s;
  }

  &::after {
    top: $checkmark-position-top + 10px;
    left: $checkmark-position-left + 8px;
    transform: rotate(-45deg);
    animation-name: right;
    animation-delay: 1.1s;
  }
}

// Animation wrapper
@keyframes toastCheckMark {
  0% {
    background-color: transparent;
    transform: scale(0);
  }
  100% {
    background-color: #0ebab6;
    transform: scale(1);
  }
}

// Checkmark keyframe mixin
@mixin checkmark($name, $width) {
  @keyframes #{$name} {
    0% {
      width: 0;
    }
    100% {
      width: $width;
    }
  }
}

// Checkmark keyframes
@include checkmark(left, $checkmark-width-left);
@include checkmark(right, $checkmark-width-right);

.profile {
  &__title {
    padding-left: 0;
    margin-bottom: 16px;
    margin-left: 30px;
    & h3 {
      font-weight: 600;
    }

    @media screen and (max-width: 480px) {
      margin-left: 14px;
    }
  }
}

.profile__card {
  height: 100%;
  padding: 0;

  .nav-item {
    text-transform: uppercase;
    font-weight: 600;
  }

  .tab-content {
    padding-top: 10px;
    padding-right: 30px;
    width: 100%;
  }

  .timeline__content {
    @include themify($themes) {
      background: themed('colorBackgroundBody');
    }

    &:after {
      @include themify($themes) {
        border-left-color: themed('colorBackgroundBody');
      }
    }
  }

  .timeline .timeline__item:nth-child(even) {
    .timeline__content {
      &:after {
        @include themify($themes) {
          border-right-color: themed('colorBackgroundBody');
        }
      }
    }
  }

  &--calendar {
    .rbc-toolbar {
      -webkit-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
  }
}

.profile__information {
  padding: 8% 8%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include directify($directions) {
    text-align: directed('left');
  }

  @media (max-width: 1345px) and (min-width: 1200px) {
    padding: 30px 15px;
  }

  @media screen and (max-width: 360px) {
    width: 100%;
    align-items: center;
  }

  .profile__data {
    @media screen and (max-width: 360px) {
      width: 100%;
      display: flex;
      text-align: center;
      padding: 0;
    }
  }
}

.profile__avatar {
  height: 140px;
  width: 140px;
  overflow: hidden;
  border-radius: 50%;
  background-color: $color-black;

  img {
    height: 100%;
  }

  @media (max-width: 1345px) and (min-width: 1200px) {
    height: 130px;
    width: 130px;
  }
}

.profile_avatar_letter {
  width: 100%;
  text-align: center;
  color: $color-background-gray;
  font-size: 36px;
  font-weight: 600px;
  line-height: 140px;
}

.profile__stats {
  display: flex;
  justify-content: space-around;
}

.profile__stat {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 15px;
}

.profile__stat-number {
  color: $color-blue;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
}

.profile__stat-title {
  margin: 0;
  color: $color-white;
  font-size: 12px;
  line-height: 14px;
}

.profile__data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .card__title {
    margin: 16px 0;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.profile__name {
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0;
  line-height: 16px;
}

.profile__work {
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 0;
  opacity: 0.6;
  line-height: 16px;
  font-size: 16px;
}

.profile__contact {
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 18px;
  font-size: 16px;
}

.profile__btn {
  margin-top: 10px;
  margin-bottom: 0;
  padding: 8px 15px;
}

.profile__activity {
  display: flex;
  border-bottom: solid 1px #e7e7e7;
  padding: 20px 0;

  &:last-child {
    border: none;
  }

  img {
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
    width: auto;

    @include directify($directions) {
      #{directed('margin-right')}: 20px;
    }

    &:last-child {
      @include directify($directions) {
        #{directed('margin-right')}: 0px;
      }
    }

    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
}

.profile__activity-avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 64px;

  @include directify($directions) {
    #{directed('margin-right')}: 20px;
  }

  img {
    height: 64px;
    width: 64px;
    margin: 0;
  }
}

.profile__activity-name {
  font-weight: 700;
  color: $color-white;
}

.profile__activity-date {
  font-weight: 400;
  color: $color-white;
  font-size: 10px;
}

.profile__current-tasks-title {
  padding: 20px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  margin: 0;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
    color: themed('colorText');
  }

  span {
    color: $color-additional;
    font-size: 13px;
  }
}

.profile__current-tasks {
  position: relative;
  padding-bottom: 40px;
}

.profile__current-task {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin: 0 20px;
  padding: 10px 0 10px 0;

  @include directify($directions) {
    #{directed('padding-right')}: 0;
    #{directed('padding-left')}: 0;
    text-align: #{directed('left')};
  }

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
    color: themed('colorText');
  }

  &:last-of-type {
    border-bottom: none;
  }

  &--disabled {
    cursor: default;
    opacity: 0.7;
  }

  label {
    margin-right: 35px;
  }

  .badge {
    text-transform: uppercase;
    font-size: 9px;
    padding: 6px 10px;
    display: inline-table;
    height: 20px;
    color: $color-white;

    @include directify($directions) {
      #{directed('margin-left')}: auto;
      #{directed('margin-right')}: 5px;
    }

    &.badge-info {
      background-color: $color-accent;
    }

    &.badge-error {
      background-color: $color-red;
    }
  }
}

.profile__current-task-checkbox {
  &--disabled {
    cursor: default;
  }

  @include directify($directions) {
    #{directed('left')}: 0px;
  }
}

.profile__see-all-tasks {
  position: absolute;
  bottom: 20px;

  @include directify($directions) {
    #{directed('right')}: 20px;
  }
}

.profile-summary__info {
  margin-bottom: 5px;
  @include directify($directions) {
    text-align: directed('left');
  }

  td {
    @include directify($directions) {
      #{directed('padding-left')}: 10px;
    }
  }

  th {
    color: $color-additional;
    font-weight: 500;
  }

  td,
  th {
    padding-bottom: 5px;
  }
}

.profile__password__reset {
  padding: 1% 13%;

  @media screen and (max-width: 480px) {
    padding: 1% 15%;
  }
}

/* custom */

.profile-row {
  margin-left: 0;

  @media screen and (max-width: 480px) {
    margin-right: 0;
  }
}

.profile-col {
  padding-left: 0;
  @media screen and (max-width: 480px) {
    padding-right: 0;
  }
}

.profile__container {
  padding-left: 0;
  @media screen and (max-width: 480px) {
    padding-right: 0;
  }
}

.profile__card__content {
  box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
  border-radius: 18px;
  padding-bottom: 0;
  margin-bottom: 20px;
}

.left__card {
  margin-left: 30px;
  @media screen and (max-width: 480px) {
    margin-left: 0;
    margin-bottom: 0;
  }
}

.profile__update-password-btn {
  margin-top: 26px;
}

.profile__toolbar {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 18px;
  & .btn {
    border-radius: 8px;
    font-size: 14px;
    padding: 5px 15px;
  }
}

.profile__ranked-question {
  margin-bottom: 30px;
}

.profile__ranked-question-title {
  margin-bottom: 16px;
  font-weight: 600;
}

.profile__card__content__radio-type-question {
  font-weight: 600;
}

.profile_layout {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.profile__avatar-container {
  z-index: 1;
  width: 100%;
  height: 100%;
  outline: none;
  display: flex;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  align-items: center;
  justify-content: center;
}

.profile-picture-btn {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 0;
  color: rgb(255, 255, 255);
  background-color: $color-black;
  &:hover,
  &:focus {
    opacity: 0.8;
  }

  & .profile-photo-btn {
    all: unset;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    & i {
      font-size: 22px;
      margin-bottom: 4px;
    }
  }
}

.upload-photo--active {
  opacity: 0.8;
}

.crop-container {
  position: relative;
  height: 100%;
}

.controls {
  width: 50%;
  display: flex;
  align-items: center;
  margin: 1% 1% 1% 25%;
}

.slider {
  padding: 22px 0px;
}

.profile-picture-upload__modal {
  max-width: 1600px;
  width: 28%;
  height: 80%;

  &__content {
    height: 100%;
  }

  &__body {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.profile-picture-remove-btn {
  transition: all 0.3s;
  position: absolute;
  top: 110px;
  right: 50px;
  z-index: 10;
  cursor: pointer;
  text-transform: uppercase;
  padding: 2px 7px;
  background: transparent;
  color: white;
  font-size: 9px;
  border: 1px solid white;
  line-height: 10px;
}

@media screen and (max-width: 480px) {
  .profile_layout {
    flex-direction: column;
  }

  .profile {
    width: 100%;
  }

  .profile__avatar {
    height: 140px;
    width: 140px;
  }

  .profile-picture-upload__modal {
    width: 95%;
  }

  .profile__information {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.resume-upload-button__margin {
  margin-top: 20px;
}

.resume-upload-button__margin > button {
  margin-bottom: 0;
  width: 210px;
  font-weight: 600;
}

.update-password-button-toolbar {
  padding-top: 0;
  margin-top: 0;
}

.update-password-button-toolbar > button {
  width: 210px;
  font-weight: 600;
  margin: 0;
}

@media screen and (max-width: 480px) {
  .resume-upload-button__margin {
    margin-top: 10%;
    margin-bottom: 0;
  }
}

.profile__update_btn {
  margin-top: 16px;
}

.profile__toolbar {
  display: flex;
  justify-content: flex-end;
  & .btn {
    border-radius: 8px;
    font-size: 14px;
    padding: 5px 15px;
  }
}

.profile__update_btn_values {
  margin-top: 60px;
}

.user-value-question {
  margin: 24px 0 8px 0;
}

@media screen and (max-width: 480px) {
  .profile-tab-panel-box-row {
    margin: 0;
  }
}

@media screen and (max-width: 480px) {
  .MuiMenuItem-root {
    white-space: unset !important;
  }
}
